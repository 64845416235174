import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { sepResolve } from '@separate/utilities/routes';

let handled = false;

export default function Custom200() {
  const { replace, asPath, push } = useRouter();

  React.useEffect(() => {
    if (handled || !replace) return;
    const path = asPath;
    const pathname = window.WelcomeTech?.originalPathname || '';
    console.log("Inside of 200 file, original location = ", pathname);

    // don't resolve /articles, to make sure if it exist after migration from contentstack.
    if(asPath.includes("/articles") || asPath.includes("/article")) {
      // this means article route was available (200 OK) but article is missing in static file should be (404).
      push("/404");
      return;
    }

    const locale = pathname.startsWith('/en/') ? 'en' : 'es';
    handled = true;

    const resultingPath = sepResolve(path, locale);
    replace(resultingPath);
  }, [replace, asPath, push]);

  return null;
}
